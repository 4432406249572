<template>
  <app-form-view
    app="equity"
    model="equitycategory"
    api-url="equity/equity-category/"
    :title="$t('menu.equityCategory')"
    :state="formData.state"
    :stateItems="stateItems"
    :approveItems="approveItems"
    v-model="formData"
  >
    <template v-slot="{ view }">
      <v-row>
        <v-col cols="12" lg="6" class="app-input--approve-wrapper">
          <app-input
            name="name"
            rules="required"
            :label="$t('fields.equityCategoryName')"
            :view="view"
            v-model="formData.name"
          />
          <app-input
            v-if="isSentToApprove"
            name="approveName"
            type="switch"
            class="approve"
            :label="$t('fields.approve')"
            v-model="formData.approve_name"
          />
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12" lg="12">
          <app-input
            name="active"
            type="switch"
            :label="$t('fields.active')"
            :view="view"
            v-model="formData.active"
          />
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12" lg="12">
          <app-input
            name="Description"
            type="html-editor"
            :label="$t('fields.description')"
            :view="view"
            v-model="formData.description"
          />
        </v-col>
      </v-row>
    </template>
  </app-form-view>
</template>

<script>
import AppFormView from '@components/AppFormView'
import AppInput from '@components/AppInput.vue'

export default {
  name: 'equity-category-form',
  components: {
    AppInput,
    AppFormView
  },
  data() {
    return {
      formData: {
        state: 'draft'
      },
      approveItems: ['approve_name'],
      loading: false
    }
  },
  computed: {
    stateItems() {
      return [
        { label: this.$t('label.draft'), value: 'draft' },
        { label: this.$t('label.sentApprove'), value: 'sent_to_approve' },
        { label: this.$t('label.approve'), value: 'approved' }
      ]
    },
    isSentToApprove() {
      return this.formData.state === 'sent_to_approve'
    }
  },
  created() {
    this.$store.dispatch('addBreadcrumb', {
      name: 'equityCategoryCreate'
    })
  },
  watch: {
    'formData.name': function (value) {
      if (this.$route.name === 'equityCategoryEdit') {
        this.$store.dispatch('addBreadcrumb', {
          name: 'equityCategoryEdit',
          text: value ?? 'N/A',
          to: {
            name: 'equityCategoryEdit',
            params: { id: this.$route.params.id }
          }
        })
      }
    }
  },
  methods: {}
}
</script>
